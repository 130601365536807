#ProjectSection {
	background-color: #101110;
	padding: 5% 10%;
	margin-top: 3%;
	width: 100%;
}
#projectsMainHeadingDiv {
	margin-bottom: 60px;
	text-align: center;
}
#projectsMainHeadingDiv h2 {
	color: #ffffff;
	margin-top: 20px;
}

.carousel {
	position: relative;
	width: 80%;
	margin: auto;
	overflow: hidden;
}

.carousel-inner {
	display: flex;
	transition: transform 0.5s ease-in-out;
}

.carousel-item {
	min-width: 100%;
	opacity: 0;
	transition: opacity 4s ease-in-out;
}

.carousel-item.active {
	opacity: 1;
}

.carousel-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background: rgba(0, 0, 0, 0.5);
	border: none;
	color: white;
	font-size: 2rem;
	cursor: pointer;
	z-index: 1;
}

.carousel-button.left {
	left: 10px;
}

.carousel-button.right {
	right: 10px;
}
.project-image {
	flex-shrink: 0;
	width: 350px;
	height: auto;
	border-radius: 8px;
}

.project-title {
	color: #fff;
	margin: 0 0 10px 0;
}
.project-description {
	color: whitesmoke;
	margin: 0;
}

.project-card {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #000;
	border: 1px solid #67fcf0;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin: 20px;
	padding: 20px;
	overflow: hidden;
	transition: transform 0.2s;
}

.project-details {
	flex: 1;
	padding-right: 20px;
}
@media only screen and (max-width: 430px) {
	.project-card {
		flex-direction: column-reverse;
	}

	#ProjectSection {
		padding: 3% 0;
	}

	.carousel {
		width: 95%;
	}

	.project-title {
		margin: 10px 0;
		line-height: 1.4;
		font-size: 1.7rem;
	}
}

.carousel-inner {
	cursor: grab;
}

.carousel-inner:active {
	cursor: grabbing;
}
