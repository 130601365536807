#mainNavbar {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 50px;
    z-index: 1;
  }
  #insideNav {
    border: 2px solid #67FCF0;
    border-radius: 25px;
    background: rgba(0,0,0,.8);
    backdrop-filter: blur(20.6333px);
  }
  .navLink {
    font-size: 1.2rem;
    font-family: 'Ubuntu', sans-serif;
    font-weight: lighter;
    text-decoration: none;
  }
  .navLink:hover {
    color: #67FCF0;
  }
  .liNav {
    display: inline-block;
    list-style-type: none;
    margin: 15px;
  }
  .liNav, .navLink {
    color: #ffffff;
  }
  .ulNav {
    list-style-type: none;
    padding: 0 10px;
    margin: 0;
  }

  .hover-3 {
    --b: 0.1em;
    --c: #67FCF0;

    color: rgb(255, 255, 255);
    padding-block: var(--b);
    background:
    linear-gradient(var(--c) 50%,#000 0) 0% calc(100% - var(--_p,0%))/100% 200%,
    linear-gradient(var(--c) 0 0) 0% var(--_p,0%)/var(--_p,0%) var(--b) no-repeat;
    -webkit-background-clip: text,padding-box;
    background-clip: text,padding-box;
    transition: .3s var(--_s,0s) linear,background-size .3s calc(.3s - var(--_s,0s));
  }
  .hover-3:hover {
    --_p: 100%;
    --_s: .3s;
  }
  @media screen and (max-width: 400px) {
   .navLink {
    font-size: .8rem;
   }
   #mainNavbar {
    bottom: 20px;
   }
  }