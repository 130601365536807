#contactSection {
    padding: 4% 20%;
    }
    .inputField {
      margin-top: 20px;
      width: 80%;
      text-align: center;
    }
    .messageBtn {
      margin-top: 30px;
    }
#contactFormHeading, .btn-outline-info {
  color: #ffffff;
}
.btn-outline-info:hover {
  background-color: #67FCF0;
}
#contactBoy, #contactFormHeading {
  display: inline-block;
}
#contactBoy {
  width: 50px;
  height: auto;
}
#contactMeImg {
  width: 100%;
}