#skillSection {
	text-align: center;
}
.flex-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.skillImg {
	width: 50px;
}
.skillDiv {
	margin: 10px;
	padding: 40px;
	border: 1px solid black;
	border-radius: 7%;
	border: 2px solid #67fcf0;
	background-color: #ffffff;
	cursor: pointer;
}
#mainSkillDiv {
	margin: 4% 20% 0 20%;
}
.skillsFirstHeading {
	font-family: "Ubuntu", sans-serif;
	font-weight: 700;
	text-align: center;
	color: #ffffff;
	width: 100%;
	margin: 30px 0;
}
#skillsMainHeading {
	width: 100%;
}
.UdemyLogo {
	width: 55px;
}
.onlineCourses {
	display: inline-block;
	padding: 20px;
}
.courseDetails h3 {
	color: #ffffff;
}
.courseDetails p {
	color: #ffffffad;
}
.onlineCoursesSection {
	margin: 100px 0;
}
.freecodecampLogo {
	width: 40px;
}
.hvr-grow {
	scale: 1;
	transition: 250ms ease-out;
}
.hvr-grow:hover {
	scale: 1.08;
	transition: 250ms ease-in;
}
@media only screen and (max-width: 430px) {
	#mainSkillDiv {
		margin: 5%;
	}
}