#text-format {
	width: 80%;
	margin: auto;
	padding: 30px 0 60px 0;
}

#text-format h1 {
	text-align: center;
	color: white;
}
#text-to-format {
	width: 100%;
}

#text-format-btn-div button:not(:last-child) {
	margin-right: 10px;
}

#text-format-btn-div {
	margin-top: 20px;
}

.gd-icon {
	width: 20px;
	position: absolute;
	top: 0;
	right: -30px;
}
#text-area {
	position: relative;
}
