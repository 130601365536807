.mailto,
.mailto:hover {
	text-decoration: none;
	color: rgb(0, 0, 0);
}
.downloadResume {
	text-decoration: none;
}
#aboutMeLink {
	text-decoration: none;
	color: #34b7f1;
}
#aboutMeLink:hover {
	color: #f78550;
}
.knowMore {
	color: #fae900;
	text-decoration: none;
	font-family: "Ubuntu", sans-serif;
	font-weight: 600;
	font-size: 25px;
}
.knowMore:hover {
	color: rgb(255, 255, 255);
}
.heroBtn {
	background-color: #101110d0;
	font-weight: bold;
	text-transform: uppercase;
	border-radius: 5px;
	font-size: 16px;
	padding: 10px 20px;
}
.heroBtn:hover {
	background-color: #34b8f1 !important;
}
.btn-dark {
	border: 1px solid #67fcf0;
}
.blinking-cursor {
	user-select: none;
	color: #67fcf0;
	animation: blink 1s steps(2, start) infinite;
}
#download-resume-btn {
	background-color: #34b8f1;
	color: #000;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
}

@keyframes blink {
	to {
		visibility: hidden;
	}
}

@-webkit-keyframes hvr-buzz {
	50% {
		-webkit-transform: translateX(3px) rotate(2deg);
		transform: translateX(3px) rotate(2deg);
	}
	100% {
		-webkit-transform: translateX(-3px) rotate(-2deg);
		transform: translateX(-3px) rotate(-2deg);
	}
}
@keyframes hvr-buzz {
	50% {
		-webkit-transform: translateX(3px) rotate(2deg);
		transform: translateX(3px) rotate(2deg);
	}
	100% {
		-webkit-transform: translateX(-3px) rotate(-2deg);
		transform: translateX(-3px) rotate(-2deg);
	}
}

@keyframes bg-scrolling-reverse {
	100% {
		background-position: 50px 50px; /* Specify your background position values */
	}
}

@keyframes bg-scrolling {
	0% {
		background-position: 50px 50px; /* Specify your background position values */
	}
}
#title {
	color: #000000;
	/* animation: bg-scrolling-reverse 2s infinite linear; */
	background-image: url("../images/mountain.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	text-align: center;
	overflow: hidden;
	min-height: 100vh;
}
.headline {
	font-weight: 900;
	font-family: "Ubuntu", sans-serif;
	padding: 4rem 0 1rem 0;
	font-size: 70px;
	line-height: 1.5;
}
#typewriter {
	font-size: 4.2rem;
	color: #212f3c;
}
.tagline {
	font-family: "Ubuntu", sans-serif;
}
.contactBtn {
	margin: 3%;
	width: 220px;
	display: inline-block;
	text-align: center;
}
.heroLinkdin,
.heroGithub {
	font-size: 40px;
	padding: 0 30px;
	color: #ffffff;
}
.heroLinkdin:hover {
	color: #0a66c2;
}
.heroGithub:hover {
	color: #000000;
}
