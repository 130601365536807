.floating-menu {
	z-index: 1000;
	cursor: grab;
}

.floating-menu:active {
	cursor: grabbing;
}

.menu-btn {
	width: 40px;
	height: 47px;
	border: 10px solid #f78550;
	border-radius: 40px;
	background-color: transparent;
	color: #007bff;
	font-size: 16px;
	cursor: pointer;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	transition: transform 0.3s ease, background-color 0.3s ease;
}

.menu-btn:hover {
	background-color: rgba(0, 123, 255, 0.1);
}

.menu-btn:active {
	transform: scale(0.9);
}

.menu-btn:active {
	transform: scale(0.9);
}

.menu-items {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.menu-item {
	position: absolute;
	width: 50px;
	height: 50px;
	/* background-color: #67fcf0; */
	color: white;
	font-size: 18px;
	border: none;
	border-radius: 50%;
	transform: translate(-50%, -50%) scale(0);
	transition: transform 0.3s ease, opacity 0.3s ease;
	opacity: 0;
	pointer-events: none;
}

.menu-item img {
	width: 3rem;
	height: 3rem;
}

.menu-items.active .menu-item {
	pointer-events: all;
	opacity: 1;
}

.menu-item:hover {
	scale: 1.1;
	transition: ease-in-out 0.4s;
}

.menu-items.active .menu-item:nth-child(1) {
	transform: translate(-50%, -180%) scale(1);
}

.menu-items.active .menu-item:nth-child(2) {
	transform: translate(-180%, -50%) scale(1);
}

.menu-items.active .menu-item:nth-child(3) {
	transform: translate(-50%, 80%) scale(1);
}

.menu-items.active .menu-item:nth-child(4) {
	transform: translate(80%, -60%) scale(1);
}

@media (max-width: 768px) {
	.menu-btn {
		width: 40px;
		height: 43px;
		font-size: 10px;
	}
}
