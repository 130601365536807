@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: "Ubuntu", sans-serif;
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
	background-color: #010001;
}

#land {
	position: absolute;
}
.slide-in-left {
	animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-in-left {
	0% {
		transform: translateX(-1000px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@media (max-width: 990px) {
	#contactForm {
		width: 100%;
	}
	.inputField {
		width: 100%;
	}
	.headline {
		font-size: 2.3rem;
		height: 400px;
		overflow: auto;
	}
	.testimonials-text {
		font-size: 1.5rem;
		margin-top: 40px;
	}
	.testimonial-name {
		margin-top: 60px;
	}
	#contactMeImg {
		padding: 15% 0;
	}
}
@media (max-width: 450px) {
	#insideNav {
		max-width: 100%;
	}
	#title {
		height: 600px;
	}
	.headline {
		height: auto;
		font-size: 4rem !important;
		padding-bottom: 5px !important;
		padding-top: 6rem !important;
	}
	#typewriter {
		height: 250px;
		font-size: 3.8rem !important;
		color: #212f3c;
	}
	#contactSection {
		padding: 4% 8% !important;
	}
	.inputField {
		width: 100% !important;
	}
	#footer {
		height: 400px;
	}
	#text-format-btn-div > button {
		margin-top: 10px;
	}
}

.hvr-grow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
.progress-container {
	width: 100%;
	height: 8px;
	background: #67fcf0;
	position: fixed;
	z-index: 2;
}
.progress-bar {
	height: 8px;
	background: #f78550;
	width: 0%;
}
#projectBody {
	color: #ffffff;
	font-size: 50px;
}

/** Page Not Found */
.goBackToHome {
	--color: #00a97f;
	--color2: rgb(10, 25, 30);
	padding: 0.8em 1.75em;
	background-color: transparent;
	border-radius: 6px;
	border: 0.3px solid var(--color);
	transition: 0.5s;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	z-index: 1;
	font-weight: 300;
	font-size: 17px;
	font-family: "Roboto", "Segoe UI", sans-serif;
	text-transform: uppercase;
	color: var(--color);
}

.goBackToHome::after,
.goBackToHome::before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	transform: skew(90deg) translate(-50%, -50%);
	position: absolute;
	inset: 50%;
	left: 25%;
	z-index: -1;
	transition: 0.5s ease-out;
	background-color: var(--color);
}

.goBackToHome::before {
	top: -50%;
	left: -25%;
	transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.goBackToHome:hover::before {
	transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.goBackToHome:hover::after {
	transform: skew(45deg) translate(-50%, -50%);
}

.goBackToHome:hover {
	color: var(--color2);
}

.goBackToHome:active {
	filter: brightness(0.7);
	transform: scale(0.98);
}
