#footer {
	background-color: #101110;
	text-align: center;
	padding: 70px 0;
	height: 300px;
}
.social-icon {
	margin: 0 12px;
	font-size: 1.3rem;
	color: rgb(255, 255, 255);
}
.social-icon:hover {
	color: #67fcf0;
}
.main-social-icons {
	margin-bottom: 15px;
}
.social-icon-a {
	text-decoration: none;
}
.copyright-section {
	font-family: "Montserrat", sans-serif;
	margin-top: 3%;
	color: rgb(255, 255, 255);
}
